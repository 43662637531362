import logo from "./logo.png";
import "./App.css";
import { ConnectButton } from "360dialog-connect-button";
import styled from "styled-components";

const StyledConnectButton = styled(ConnectButton)`
  outline: none;
  background: #b40204;
  color: white;
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 28px;
  border: none;
`;

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <StyledConnectButton
          partnerId={"lGiRg9PA"}
          callback={(callbackObject) => {
            console.log("Client ID: " + callbackObject.client);
            console.log("Channel IDs: " + callbackObject.channels);
            if (callbackObject.revokedChannels) {
              console.log(
                "Revoked Channel IDs: " + callbackObject.revokedChannels
              );
            }
          }}
          className="" // <-- Insert your own styles via className definition or through inline styling
          label="Create your Qikchat Account"
          queryParameters={{
            redirect_url: "https://portal.qikchat.in/",
            plan_selection: "basic",
            hosting_type: "cloud",
          }}
        />
        <StyledConnectButton
          partnerId={"N8gY9BPA"}
          callback={(callbackObject) => {
            console.log("Client ID: " + callbackObject.client);
            console.log("Channel IDs: " + callbackObject.channels);
            if (callbackObject.revokedChannels) {
              console.log(
                "Revoked Channel IDs: " + callbackObject.revokedChannels
              );
            }
          }}
          className="" // <-- Insert your own styles via className definition or through inline styling
          label="Create your Qikchat Account (INR)"
          queryParameters={{
            redirect_url: "https://portal.qikchat.in/",
            plan_selection: "basic",
            hosting_type: "cloud",
          }}
        />
      </header>
    </div>
  );
}

export default App;
